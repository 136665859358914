<template>
  <!-- page2 -->
  <div class="width-100 height-100 content" id="card1">
    <video
      class="end_video none"
      src="video/11-09-01.mp4"
      muted
      width="100%"
      height="100%"
      ref="endVideo"
      preload="auto"
    ></video>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
import src1 from "../../assets/img/90s/pic2.png";
import src3 from "../../assets/img/90s/pic.png";
import src4 from "../../assets/img/90s/pic4.png";
// import {Page2Data} from "./data";

export default {
  name: "TwoComponent",
  props: ["cardData","secData","tempAduioArr"],
  data: function () {
    return {
      audiosrc: "",
      src1: "",
      src3: "",
      src4: "",
      time: "",
      jys: "",
      address: "",
      Page2Data: '',
      logo:"",
      company_name:"",
      intro:"",
    };
  },
  created() {
    // this.getdata(this.secData[0]);
  },  
  computed:{
    activation() {              
      return () => { // 使用JavaScript闭包，进行传值操作   交易所背景色区分
          if (this.cardData[0][1].jys == "深圳证券交易所"){
            return {'background':'linear-gradient(180deg, #C898FF 0%, #7823EC 100%)'}
          } else if (this.cardData[0][1].jys == "上海证券交易所"){
            return {'background':'linear-gradient(180deg, #98A9FF 0%, #2365EC 100%)'}
          } else if (this.cardData[0][1].jys == "北京证券交易所"){
            return {'background':'linear-gradient(180deg, #FFC68E 0%, #D86008 100%)'}
          }
                        
      }
    }
  },
  watch: {
    cardData: {
      handler(val) {
        if (val.length > 0) {
          this.Page2Data = val[0];
          this.src1 = this.Page2Data[2].src1;
          this.src3 = this.Page2Data[2].src2;
          this.src4 = this.Page2Data[2].src3;
          this.logo = this.Page2Data[2].src;
          this.company_name = this.Page2Data[0].company_name2;
          this.intro = this.Page2Data[0].intro;
        }
      },
      
      deep: true,
      immediate: true,
    },
  },
  mounted() {

  },
  methods: {
    // async getdata(options){
    //   this.Page2Data = await Page2Data(options);
      // this.src1 = this.Page2Data[2].src1;
      // this.src3 = this.Page2Data[2].src2;
      // this.src4 = this.Page2Data[2].src3;
    // },
    // 页面card一个接一个播放
    async playCardStart() {
      if (!this.tempAduioArr[1]) return;
      this.audiosrc = this.tempAduioArr[1].audio_link;
      this.$nextTick(() => {
      });

      setTimeout(async () => {        
        await this.outPage();
        
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);
      
      await this.playPage1();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    async outPage(){
      $("#card1").fadeOut(300)
    },
    async playPage1() {
      $(".end_video").removeClass("none");
      this.$refs.endVideo.play();
    },
    getTime() {
      // 默认返回5秒
      let time = 5;
      if (!this.tempAduioArr[1]) return;
      let audioTime = Math.ceil(this.tempAduioArr[1].audio_length);
      if (audioTime > time) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[1].audio_link,
        pageLen: time,
      };
    },
  },
};
</script>
<style scoped lang="less">
#card1 {
  // background: url("../../assets/img/90s/bg.png");
  // box-sizing: border-box;
  width: 70%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  .none {
    display: none;
  }
}
</style>

