<template>
  <!-- page5 -->
  <div class="width-100 height-100" id="card1">
    <audio :src="audiosrc" ref="audio" preload="auto"></audio>
    <div class="head none">
      <div class="img">
        <img src="../../assets/img/90s/icon.png" alt="" />
      </div>
      <div class="text">营业收入</div>
      <div class="company_name">{{Page5Data[0].company_name}}</div>
    </div>
    <div class="content none">
      <div class="title ">近5年营业收入</div>
      <div class="dec none">
        <div>最新年报收入</div>
        <div class="mark"><span id="num1">{{Page5Data[0].income}}</span><span>亿元</span></div>
        <div>同比{{Page5Data[0].updown}}</div>
        <div class="mark"><span id="num2">{{Page5Data[0].ratio}}</span><span>%</span></div>
      </div>
      <bar-chart ref="refBarChart3" id="refBarChart3" customClass="c_bar2">
      </bar-chart>
      <svg width="300" height="300" class="svg_gradient">
        <defs>
          <radialGradient
            id="radial1"
            fx="100%"
            fy="100%"
            cx="50%"
            cy="100%"
            r="100%"
            gradientUnits="objectBoundingBox"
          >
            <stop
              offset="0%"
              stop-color="rgba(153, 170, 255, 1)"
              stop-opacity="1"
            />
            <stop
              offset="100%"
              stop-color=" rgba(34, 100, 235, 1)"
              stop-opacity="1"
            />
          </radialGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
import barChart from "@/components/barChart/index_v1.0.vue";
import { Page5Data } from "./data";
export default {
  name: "TwoComponent",
  data: function () {
    return {
      audiosrc: "",
      allPositive: [],
      Page5Data: [],
      xData: [],
      yData:[],
    };
  },  
  created() {
    // this.getdata(this.secData[0]);
  },
  props: ["cardData","secData", "tempAduioArr"],
  computed: {},
  components: { barChart },
  mounted() {},
  watch: {
        cardData: {
      handler(val) {
        if (val.length > 0) {
          this.Page5Data = val[3];
          this.allPositive = this.Page5Data[0].allPositive;
        }
      },
      
      deep: true,
      immediate: true,
    },
  },
  methods: {   
    async getdata(options){
      this.Page5Data = await Page5Data(options);
      this.allPositive = this.Page5Data[0].allPositive;
    },
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 2000,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    playIn3(refName) {
      this.$refs[refName].BarChart(this.allPositive, {
        x: (d) => d.letter,
        y: (d) => d.frequency,
        width: 1407,
        height: 500,
        marginLeft: 60,
        duration: 1500,
        delay: 60,
        ease: "easeCircle",
        isShowNumber: false,
        xTickNumber: 5,
        yTickNumber: 5,
      });
    },
    // 页面card一个接一个播放
    async playCardStart() {
      this.audiosrc = this.tempAduioArr[4].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });

      setTimeout(async () => {
        await this.outPage();
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();
    },
    async outPage(){
      $("#card1").fadeOut(300)
    },
    getTime() {
      // 默认返回5秒
      let time = 5;
      let audioTime = Math.ceil(this.tempAduioArr[4].audio_length);
      if (audioTime > 5) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[4].audio_link,
        pageLen: time,
      };
    },
    async playPage1() {
      $(".head").removeClass("none");
      $(".head").addClass("animate__animated animate__fadeInDownBig");
      await sleep(1000);
      $(".content").removeClass("none");
      $(".content").addClass("animate__animated animate__fadeIn");
      this.playNumVaryIn("#num1", 2);
      this.playNumVaryIn("#num2", 2);
      // await sleep(1000);
      // $(".content .title").removeClass("none");
      // $(".content .title").addClass("animate__animated animate__flash");
      // $(".content .dec").removeClass("none");
      // $(".content .dec").addClass("animate__animated animate__fadeIn");
       
      await sleep(1000);
      this.playIn3("refBarChart3");
    },
  },
};
</script>
<style scoped lang="less">
#card1 {
  // background: url("../../assets/img/90s/bg.png");
  // box-sizing: border-box;
  .none{
    visibility: none;
  }
  .head {
    display: flex;
    position: absolute;
    top: 95px;
    left: 69px;
    color: #fff;
    justify-content: space-around;
    height: 60px;
    line-height: 53px;
    .img {
      width: 78px;
      height: 53px;
      margin-right: 34px;
    }
    .text {
      width: 208px;
      height: 52px;
      font-size: 52px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      margin-right: 14px;
    }
    .company_name {
      width: 208px;
      height: 60px;
      background: #3875f4;
      border-radius: 16px 16px 16px 4px;
      font-size: 40px;
      font-weight: 800;
      text-align: center;
    }
  }
  .content {
    position: absolute;
    left: 180px;
    top: 230px;
    width: 1560px;
    height: 730px;
    background-color: linear-gradient(180deg, #33475d 0%, #11121a 100%);
    box-shadow: 0px 10px 30px rgba(6, 19, 29, 0.6);
    // opacity: 0.7;
    border-radius: 24px;
    background: url("../../assets/img/90s/biaoti5.png") no-repeat;
    .title {
      width: 237px;
      height: 36px;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
      position: absolute;
      top: 8px;
      left: 27px;
      z-index: 999;
    }
    .dec {
      display: flex;
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.89;
      position: absolute;
      top: 113px;
      left: 395px;
      height: 67px;
      line-height: 67px;
      .mark {
        font-size: 46px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ff8d1a;
        opacity: 0.89;
        margin-left: 15px;
        margin-right: 82px;
        line-height: 57px;
      }
    }
    .c_bar2 {
      position: absolute;
      left: 80px;
      top: 182px;
      width: 1407px;
      height: 500px;
      margin-bottom: 20px;
      /deep/ .y_label {
        font-size: 18px;
      }
      /deep/ .axis_x {
        font-size: 24px;
        .tick_line,
        .domain {
          display: none;
        }
        .text {
          fill: #ffffff;
        }
      }
      /deep/ .axis_y {
        font-size: 18px;
        .tick_line,
        .domain {
          display: none;
        }
        .text {
          fill: #b1bbd0;
        }
        .tick_long_line {
          stroke: rgba(228, 231, 240, 0.2);
        }
      }
      /deep/ .bar_group rect {
        fill: url(#radial1);
        width: 80px;
      }
      /deep/ .bar_group rect:first-of-type {
        x: 167px;
      }
      /deep/ .bar_group rect:nth-of-type(2) {
        x: 434px;
      }
      /deep/ .bar_group rect:nth-of-type(3) {
        x: 696px;
      }
      /deep/ .bar_group rect:nth-of-type(4) {
        x: 957px;
      }
      /deep/ .bar_group rect:nth-of-type(5) {
        x: 1220px;
      }
    }
  }
}
</style>

