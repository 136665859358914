<template>
  <!-- page5 -->
  <div class="width-100 height-100" id="card1">
    <audio :src="audiosrc" ref="audio" preload="auto"></audio>
    <div class="head none">
      <div class="img">
        <img src="../../assets/img/90s/icon.png" alt="" />
      </div>
      <div class="text">成长能力</div>
      <div class="company_name">{{ Page12Data[0].company_name }}</div>
    </div>
    <div class="content none">
      <div class="dec">
        <div><img src="../../assets/img/90s/icon1.png" alt="" /></div>
        <div class="mark mark1">营业利润增长率</div>
        <div><img src="../../assets/img/90s/icon2.png" alt="" /></div>
        <div class="mark mark2">5年均值</div>
        <div><img src="../../assets/img/90s/icon3.png" alt="" /></div>
        <div class="mark mark3">同比</div>
      </div>
      <div id="chart1"></div>
      <svg width="300" height="300" class="svg_gradient">
        <defs>
          <radialGradient
            id="radial1"
            fx="50%"
            fy="50%"
            cx="50%"
            cy="50%"
            r="100%"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0%" stop-color="#2264EB" stop-opacity="1" />
            <stop offset="100%" stop-color="#99AAFF" stop-opacity="1" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
import { Page12Data } from "./data";
export default {
  name: "TwoComponent",
  data: function () {
    return {
      audiosrc: "",
      allPositive: [],
      Page12Data:[],
    };
  },
   props: ["cardData","secData", "tempAduioArr"],
  computed: {},
  created() {
    // this.Page10Data=Page10Data
    // this.getdata(this.secData[0]);
  },
  mounted() {
  },
  watch: {
        cardData: {
      handler(val) {
        if (val.length > 0) {
          this.Page12Data = val[10];
        }
      },
      
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async getdata(options){
      this.Page12Data = await Page12Data(options);
    },
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 1200,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    playIn3(refName) {
      this.$refs[refName].BarChart(this.allPositive, {
        x: (d) => d.letter,
        y: (d) => d.frequency,
        width: 1407,
        height: 500,
        duration: 1000,
        delay: 70,
        ease: "easeCircle",
        isShowNumber: false,
        xTickNumber: 5,
        yTickNumber: 5,
      });
    },
    // 页面card一个接一个播放
    async playCardStart() {
      this.audiosrc = this.tempAduioArr[11].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });

      setTimeout(async () => {        
        await this.outPage();
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();
    },
    async outPage(){
      $("#card1").fadeOut(300)
    },

    getTime() {
      // 默认返回5秒
      let time = 5;
      let audioTime = Math.ceil(this.tempAduioArr[11].audio_length);
      if (audioTime > 5) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[11].audio_link,
        pageLen: time,
      };
    },
    async playPage1() {
       $(".head").removeClass("none");
      $(".head").addClass("animate__animated animate__fadeInDownBig");
      await sleep(1000);
      $(".content").removeClass("none");
      $(".content").addClass("animate__animated animate__fadeIn");
      this.loadChart1();
    },
    loadChart1() {
      let myChart = this.$echarts.init(document.getElementById("chart1"));
      // 绘制图表
      myChart.setOption({
        grid:{
          left:"11%"
        },
        xAxis: [
          {
            type: "category",
            // data: ["2018", "2019", "2020", "2021", "2022"],
            data:this.Page12Data[0].xData,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              fontSize: 32,
              color: "#fff",
              margin:40

            },
            axisLine: {
              show: true,
            },
            axisTick:{
              show:false
            }
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "营业利润增长率/五年均值",
            // nameLocation:"end",
            nameTextStyle: {
              fontSize: 28,
              color: "#8DADFF",
              width: "112",
              align: "center",
              lineHeight: 60,
            },
            // min: 0,
            // max: 250,
            // interval: "auto",
            axisLabel: {
              formatter: "{value}%",
              fontSize: 28,
              color: "#8DADFF",
            },
            splitLine: {
              show: false,
            },
          },
          {
            type: "value",
            name: "同比增长",
            nameTextStyle: {
              fontSize: 28,
              color: "#EF5151",
              width: "112",
              align: "left",
              lineHeight: 60,
            },
            // min: 0,
            // max: 25,
            // interval: "auto",
            axisLabel: {
              formatter: "{value}%",
              fontSize: 28,
              color: "#EF5151",
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "营业利润增长率/五年均值",
            type: "bar",
            // data: [18.86, 9.45, -39.17, 109.57, 20.57],
            data:this.Page12Data[0].y1Data,
            barWidth: 80,            
            markLine: {
              symbol:'none',
              silent: true,
              label: {
                show: false,
              },
              lineStyle: {
                color: "#FFB01A",
                width: 4,
              },
              data: [
                {
                  yAxis: this.Page12Data[0].valueAvg,
                },
              ],
            },
            color: {
              type: "linear",
              x: 0, //右
              y: 0, //下
              x2: 0, //左
              y2: 1, //上
              colorStops: [
                {
                  offset: 0,
                  color: "#2365EB", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#96A8FF", // 100% 处的颜色
                },
              ],
            },
          },
          {
            name: "同比增长",
            type: "line",
            yAxisIndex: 1,
            // data: [9.02, -49.89, -514.5, -379.73, -81.23],
            data:this.Page12Data[0].y2Data,
            showSymbol: true,
            symbol:"emptyCircle",
            symbolSize: 16,
            lineStyle: {
              color: "#EF5151",
              width: 5,
            },
            // markLine: {
            //   symbol:'none',
            //   silent: true,
            //   label: {
            //     show: false,
            //   },
            //   lineStyle: {
            //     color: "#FFB01A",
            //     width: 4,
            //   },
            //   data: [
            //     {
            //       yAxis: -280,
            //     },
            //   ],
            // },
          },
        ],
      });
    },
  },
};
</script>
<style scoped lang="less">
#card1 {
  // background: url("../../assets/img/90s/bg.png");
  // box-sizing: border-box;
  .head {
    display: flex;
    position: absolute;
    top: 95px;
    left: 69px;
    color: #fff;
    justify-content: space-around;
    height: 60px;
    line-height: 53px;
    .img {
      width: 78px;
      height: 53px;
      margin-right: 34px;
    }
    .text {
      width: 208px;
      height: 52px;
      font-size: 52px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      margin-right: 14px;
    }
    .company_name {
      width: 208px;
      height: 60px;
      background: #3875f4;
      border-radius: 16px 16px 16px 4px;
      font-size: 40px;
      font-weight: 800;
      text-align: center;
    }
  }
  .content {
    position: absolute;
    left: 180px;
    top: 230px;
    width: 1560px;
    height: 730px;
    background: linear-gradient(180deg, #33475d 0%, #11121a 100%);
    box-shadow: 0px 10px 30px rgba(6, 19, 29, 0.6);
    opacity: 0.7;
    border-radius: 24px;
    .dec {
      display: flex;
      font-size: 28px;
      font-family: Helvetica;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.89;
      position: absolute;
      top: 45px;
      left: 535px;
      height: 67px;
      line-height: 67px;
      .mark {
        font-size: 28px;
        font-family: Helvetica;
        font-weight: bold;
        opacity: 0.89;
        margin-left: 15px;
        margin-right: 40px;
        line-height: 51px;
      }
      .mark1 {
        color: #8dadff;
      }
      .mark2 {
        color: #ffb01a;
      }
      .mark3 {
        color: #ef5151;
      }
    }
    #chart1 {
      width: 1500px;
      height: 580px;
      position: absolute;
      left: 51px;
      bottom: 52px;
    }
  }
}
</style>

