<template>
  <!-- page1 -->
  <div class="width-100 height-100 content" id="card1">
    <audio :src="audiosrc" ref="audio" autoplay="autoplay"></audio>
    <video
      class="end_video none"
      :src="src"
      muted
      width="100%"
      height="100%"
      ref="endVideo"
      preload="auto"
    ></video>
    <div class="title none">
      <div class="logo none" id ="logo">
        <img :src="logo">
      </div>
      <div class="text none" id ="text">{{company_name}}</div>
    </div>
  </div>
</template>
<script>
import {Page1Data} from "./data.js";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
export default {
  name: "OneComponent",
  data: function () {
    return {
      audiosrc: "",
      audioData: {},
      src:"",
      company_name: "",
      logo:"",
    };
  },
  mounted(){
  //  this.src = this.cardData[11][0].videoSrc;
  },

  props:["tempAduioArr","cardData"],
  watch: {
    tempAduioArr: {
      handler(val) {
        if (val.length > 0) {
          this.audioData = val[0];
        }
      },
      deep: true,
      immediate: true,
    },
    cardData: {
      handler(val) {
        if (val.length == 13) {
          this.src = val[12][0].videoSrc;          
          this.logo = val[0][2].src;
          this.company_name = val[0][0].company_name2;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {},
  methods: {
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    
    playOut() {
      this.txt.goOut.restart();
    },
    // 页面card一个接一个播放
    async playCardStart() {
      this.audiosrc = this.audioData.audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });
      setTimeout(async () => {
        
        await this.outPage();
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();      
    },
    
    async outPage(){
      // this.playOut();
      $("#card1").fadeOut(300)
    },
    async playPage1() {          
      $(".end_video").removeClass("none");       
      this.$refs.endVideo.play();      
      await sleep(1000);          
      $(".logo").removeClass("none");         
      $(".text").removeClass("none");
      this.playIn("runIn", "typeOut", "#text");
    },
    getTime() {
      let time = 1; 
      let audioTime = Math.ceil(this.audioData.audio_length);
      if (audioTime > time) {
        time = audioTime;
      }
      return {
        audio_link: this.audioData.audio_link,
        pageLen: time,
      };
    },
  },
};
</script>
<style scoped lang="less">
.title {
  position: absolute;
  display: flex;
  width:100%;
  height:100%;    
  top :264px;
  left:526px;    
  z-index: 999;
  img {
    
    width:auto;
    height: 96px;
    border-radius: 20px;
  }
  .text{

    height: 96px;
    line-height: 96px;
    margin-left: 20px;
    font-size: 96px;
    font-weight: 800;
    color:#ffffff;
  }
}
</style>

